
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0603d54abdf45d2a': html`Mit einer Anmeldequote von ${0} erreichen Sie derzeit etwa ${1} Mitarbeitenden.`,
'h610194d3aee2ff19': html`Sie können Ihre Präferenz jederzeit in Ihren <a href="${0}">Einstellungen</a> ändern.`,
'h79d594675e391090': html`Mitglieder`,
'h8bfcb3a58c034aa9': html`${0} ab ${1}`,
'hba543a1093a4962d': html`Wenn Sie die Registrierungsquote und damit die Nutzung von voiio durch Ihre Mitarbeitenden steigern möchten, nutzen Sie gern unseren kostenfreien <a href="${0}">Onboarding-Service</a> nutzen.`,
'he6a1f129534d5b5e': html`${0} mit <a href="${1}">${2}</a>`,
's000d6d215349c200': str`Bitte kommen Sie später wieder, der Vortrag beginnt am ${0}.`,
's00391e19133920b8': `schlecht`,
's04aba6130cfe5156': str`Bitte versuchen Sie es später noch einmal.`,
's08b35907b5589afa': str`nein`,
's08b73807b55c4bbe': `ein`,
's08c83907b56ac0a4': `bis`,
's0bf2c6650b942f4d': str`Bitte geben Sie die Details Ihrer Anfrage ein, damit unser Serviceteam Sie bestmöglich unterstützen kann.`,
's0cc44970c8397929': str`Beschreibung`,
's0ee2fd54fc2d6849': `Ende`,
's0f136ff2c086b760': `zurücksetzen`,
's1074123338e8d380': str`Vielen Dank für Ihre Kontaktanfrage. Wir werden uns innerhalb von 24 Stunden (werktags) über die von Ihnen bevorzugte Kontaktart bei Ihnen melden.`,
's1153970079d1c964': `jedes Alter`,
's11888524fb83396a': `Es existiert bereits ein Profil mit diesem Namen.`,
's123467b419acbc07': str`E-Mail`,
's12c6ba09833bc147': str`Buchung anzeigen`,
's145787df0ebb6919': `Teilnehmenden hinzufügen`,
's1641a1c90d9840ec': str`Entwurf`,
's19f79b1921bbcfff': `aus`,
's1c783b033e002efe': str`beschreiben Sie Ihr Anliegen in wenigen Worten`,
's1fcc8b4e81fbac2f': str`bis`,
's245abf421decbf7f': `machen Sie es sich bequem`,
's248ed9756a4bce17': str`Lade`,
's266721a2798e5d38': `Bitte warten Sie hier, der Vortrag wird bald beginnen.`,
's2952d22af0012917': str`nachmittags`,
's2e11aaa84e5435c5': str`Zeitraum`,
's31fc9c6bde865d6f': str`Telefon`,
's3c10f2bad16cff73': str`mittags`,
's3c477a11984ced5b': str`alle Angebote`,
's41bd6090e3da1205': str`Bitte warten Sie hier, der Vortrag beginnt in ${0}:${1} Minuten.`,
's4380c48fdd8e84fb': str`bevorzugte Kontaktmethode`,
's47df71a444e20378': `neutral`,
's480b227ca618e526': `morgen`,
's4cbf3a26fca1d74a': str`Position`,
's4eeeb36376f9b4a1': `Mo–Fr 9–18 Uhr`,
's51015302247f21a2': `Registrierungsquote`,
's5e81d98cf8763a7d': `mehr anzeigen`,
's602113e1cee955b3': `Formate`,
's62c8c8dd82971ae9': str`Telefonnummer eingeben`,
's647712ace784e503': str`Vielen Dank, dass sie uns ein technisches Problem gemeldet haben. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen, um nach einer Lösung zu suchen.`,
's77e7756cd557b406': `atmen`,
's7b268a8f37a31049': str`unverbindlich anfragen`,
's7c64634977425edc': `Jahr`,
's809a776ac2d5a72f': str`alle anzeigen`,
's8b9c720d031556da': `Vielen Dank für Ihr Feedback. 🤩`,
's8dfdbebc8dbc3fed': `als Profil speichern`,
's8e5a5d5c0bd446ef': str`abends`,
's8f90406e9a94abe6': str`Ort`,
's91c7555e428df0cc': str`jederzeit`,
's977a0a5727795f8e': str`…aber, unser Beratungsteam hilft Ihnen immer, das passende Angebot zu finden.`,
's9a84972a809a15fe': str`Es sind keine Buchungsoptionen verfügbar.`,
's9ac9345d6c559ed1': str`Ausgebucht`,
's9ce4d6720e9c9118': `gut`,
'sa0f364d5a5c6e35d': `Jahre`,
'sa411597d0674521e': `heute`,
'sa43a103dd3b5b38f': str`morgens`,
'sa778748d49d3582f': str`technisches Problem`,
'saba7100ca619ecf5': `Bitte fügen Sie mindestens einen Teilnehmenden hinzu.`,
'sada0514bc0d33118': `schließen Sie die Augen`,
'sadae49645df2840b': str`melden`,
'sb0d30b9f07c0a8da': `Haben Sie alle Teilnehmenden hinzugefügt?`,
'sb2b539b02f28a79a': str`Anfrage senden`,
'sb53f7a197981b790': str`ja`,
'sbd1a268f348921c1': `Zusätzliche Anmerkungen`,
'sd346461ff37f278f': str`bevorzugte Kontaktzeit`,
'sd4891d1af1c4e009': str`Huch, wir konnten keine passenden Ergebnisse finden…`,
'sdaa5555d0d080f92': str`Bitte beschreiben Sie das technische Problem, damit unser Serviceteam Ihnen helfen kann.`,
'sdab8a93f00f51367': str`offline-Angebote`,
'sdc51fb6761fd6e91': `fertig`,
'sdd6fbd38986b7842': str`Entfernung`,
'sde7c7c54703f6150': `eine weitere Person hinzufügen`,
'sdeed9e2b816b0e08': `klicken Sie um zu starten`,
'se125154e4c29b651': str`online-Angebote`,
'se608e27c0bcef805': str`Es tut uns leid, etwas ist schief gelaufen.`,
'se6f7b419052023cd': str`beliebig`,
'se715b4190539215c': `Alter`,
'sec1213d45aaf6cb1': `Profilname`,
'sf4ef59b50aa74c00': str`Vollbild`,
'sf646765c4ecc7079': `Wer nimmt teil?`,
'sf7d7a43b464b8ddc': `Alter des Kindes`,
'sfff83d536a1d457d': `entfernen`,
    };
  